const contacto = () => {
	document.addEventListener("DOMContentLoaded", function () {
		const defaultForm = document.querySelector("#form");

		if (defaultForm) {
			const nombre = defaultForm.querySelector("#nombre");
			const apellidos = defaultForm.querySelector("#apellidos");
			const email = defaultForm.querySelector("#email");
			const telefono = defaultForm.querySelector("#tel");
			const aviso = defaultForm.querySelector("#aviso");
			const submit = defaultForm.querySelector("#submit");

			aviso.addEventListener("change", function () {
				if (aviso.checked) {
					submit.disabled = false;
				} else {
					submit.disabled = true;
				}
			});

			defaultForm.addEventListener("submit", function (e) {
				if (
					nombre.value === "" ||
					nombre.value === null ||
					nombre.value === false
				) {
					nombre.classList.add("is-invalid");
					e.preventDefault();
				} else {
					nombre.classList.remove("is-invalid");
				}

				if (
					apellidos.value === "" ||
					apellidos.value === null ||
					apellidos.value === false
				) {
					apellidos.classList.add("is-invalid");
					e.preventDefault();
				} else {
					apellidos.classList.remove("is-invalid");
				}

				if (
					email.value === "" ||
					email.value === null ||
					email.value === false
				) {
					email.classList.add("is-invalid");
					e.preventDefault();
				} else {
					email.classList.remove("is-invalid");
				}

				if (
					telefono.value === "" ||
					telefono.value === null ||
					telefono.value === false
				) {
					telefono.classList.add("is-invalid");
					e.preventDefault();
				} else {
					telefono.classList.remove("is-invalid");
				}
			});
		}
	});
};

export default contacto;
